/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @next/next/no-img-element */
import PromoView from '@core_modules/checkout/components/fieldcode';
import gqlService from '@core_modules/checkout/services/graphql';
import { useDispatch, useSelector } from 'react-redux';
import {
    selectCheckoutState, setIsNewUpdate, setLoading, setCheckoutData, setRefetchItemOnly,
} from '@core_modules/checkout/redux/checkoutSlice';
import Typography from '@common/Typography';
import { formatPrice } from '@helper_currency';
import cx from 'classnames';

const DiscountSection = (props) => {
    const {
        t,
        handleOpenMessage,
        formik,
        // refetchItemCart,
        currencyCache,
    } = props;
    const [applyCouponTocart] = gqlService.applyCouponToCart({ onError: () => { } });
    const [removeCouponFromCart] = gqlService.removeCouponFromCart({ onError: () => { } });

    const dispatch = useDispatch();
    const checkout = useSelector(selectCheckoutState);
    const discountCoupon = checkout?.data?.cart?.prices?.discounts
        ? checkout.data.cart.prices.discounts.filter((discount) => discount.is_coupon)
        : [];
    const appliedCoupons = checkout?.data?.cart?.applied_coupons;
    const handlePromo = async () => {
        let cart;

        dispatch(setLoading({
            all: false,
            shipping: false,
            payment: true,
            extraFee: false,
            order: true,
            coupon: true,
        }));

        dispatch(setIsNewUpdate(true));

        const isApplied = !checkout.data.isCouponAppliedToCart;

        let cartId = '';
        if (checkout && checkout.data && checkout.data.cart && checkout.data.cart.id) {
            cartId = checkout.data.cart.id;
        }

        if (isApplied) {
            const result = await applyCouponTocart({ variables: { cartId, coupon: formik.values.coupon } });
            if (result && result.data && result.data.applyCouponToCart && result.data.applyCouponToCart.cart) {
                cart = {
                    ...checkout.data.cart,
                    ...result.data.applyCouponToCart.cart,
                };
            }
            if (cart) {
                handleOpenMessage({
                    variant: 'success',
                    text: t('checkout:message:couponApplied'),
                });
            }
        } else {
            const result = await removeCouponFromCart({ variables: { cartId } });
            if (result && result.data && result.data.removeCouponFromCart && result.data.removeCouponFromCart.cart) {
                cart = result && {
                    ...checkout.data.cart,
                    ...result.data.removeCouponFromCart.cart,
                };
                formik.setFieldValue('coupon', '');
                handleOpenMessage({
                    variant: 'success',
                    text: t('checkout:message:couponRemoved'),
                });
            }
        }

        dispatch(setLoading({ coupon: false }));

        if (cart) {
            dispatch(setCheckoutData({
                cart,
                isCouponAppliedToCart: !checkout.data.isCouponAppliedToCart,
            }));
        } else {
            await formik.setFieldError('coupon', t('checkout:message:couponError'));
        }
        dispatch(setRefetchItemOnly(true));
        dispatch(setLoading({
            all: false,
            shipping: false,
            payment: false,
            extraFee: false,
            order: false,
        }));

        // if (refetchItemCart && typeof refetchItemCart === 'function') {
        //     dispatch(setLoading({
        //         payment: true,
        //         order: true,
        //         coupon: true,
        //         shipping: true,
        //     }));
        //     await refetchItemCart();
        //     dispatch(setLoading({
        //         all: false,
        //         shipping: false,
        //         payment: false,
        //         extraFee: false,
        //         order: false,
        //         coupon: false,
        //     }));
        // }
    };

    return (
        <div>
            <PromoView
                id="coupon"
                name="coupon"
                placeholder="Enter your coupon code"
                action={handlePromo}
                onChange={formik.handleChange}
                value={formik.values.coupon}
                disabled={checkout.loading.coupon || !checkout.data.cart || appliedCoupons?.length > 0}
                toggleField={checkout.data.isCouponAppliedToCart}
                loading={checkout.loading.coupon}
                error={!!formik.errors.coupon}
                errorMessage={formik.errors.coupon}
                inputClassName="placeholder:text-[#6C7594] text-[#6C7594]"
            />
            {appliedCoupons?.length > 0 && (
                <>
                    {appliedCoupons.map((coupon) => (
                        <div className="relative bg-cloudy-10 rounded py-[11px] pl-2 pr-9 mt-2">
                            <div>
                                <Typography variant="bd-2" className="text-base font-semibold" color="text-[#5D6777]">
                                    Promo code:
                                </Typography>
                                <Typography variant="bd-2" className="ml-2 text-base font-semibold" color="text-[#262626]">
                                    {coupon.code}
                                </Typography>
                            </div>
                            <img
                                alt="close"
                                onClick={handlePromo}
                                className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
                                src="/assets/img/close.svg"
                            />
                        </div>
                    ))}
                </>
            )}
            {discountCoupon?.length > 0 && (
                <div className="border-b border-cloudy-40 mt-3">
                    {discountCoupon.map((discount) => (
                        <div className={cx('flex flex-row justify-between items-center gap-3', 'pb-3')}>
                            <div class="flex items-center gap-1.5">
                                <img alt="discount" src="/assets/img/checkout/discount.svg" />
                                <Typography variant="bd-2b" className="text-lg font-medium leading-5 text-[#4E4E4E]" color="text-[#161616]">
                                    {discount.label}
                                </Typography>
                            </div>
                            <Typography variant="bd-2" className="text-lg font-medium grow shrink-0 text-right" color="text-[#3C8A3C]">
                                -
                                {formatPrice(discount.amount.value, discount.amount.currency, currencyCache)}
                            </Typography>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default DiscountSection;
