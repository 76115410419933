/* eslint-disable max-len */
import Button from '@common_button';
import TextField from '@common_forms/TextField';
import Typography from '@common_typography';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';

const FieldPoint = ({
    onChange = () => {},
    value = '',
    placeholder = '',
    action,
    disabled = false,
    id = null,
    name = null,
    error,
    errorMessage = 'error',
    loading = false,
    toggleField = false,
    styleFrame = {},
    styleFrameText = {},
    styleTextField = {},
    inputClassName,
}) => {
    const { t } = useTranslation(['common']);
    return (
        <div className="w-full mt-[5px]" id={id}>
            <Typography className="text-lg font-semibold mb-[13px]" color="text-[#161616]">Coupon Code:</Typography>
            <div className="w-full flex flex-row items-start gap-3 relative mt-[13px]" style={styleFrame}>
                <TextField
                    id={`${id}Textfield`}
                    name={name}
                    styleFrameText={styleFrameText}
                    styleTextField={styleTextField}
                    disabled={!!(disabled || toggleField)}
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    className="w-full"
                    classWrapper="w-full !border-none"
                    inputProps={{
                        className: `bg-neutral-white py-[9px] pl-2.5 ${inputClassName}`,
                    }}
                    absolute={false}
                    hintProps={{
                        displayHintText: error,
                        hintText: errorMessage,
                        hintType: 'error',
                        className: 'max-w-[320px]',
                    }}
                />
                <Button
                    variant="plain"
                    onClick={(disabled || loading || value === '') ? () => {} : action}
                    disabled={disabled || loading || value === ''}
                    className={cx(
                        'swift-action-apply !bg-[rgb(0,0,0,0)] absolute right-[14px] !p-0 top-[19px] -translate-y-1/2',
                        (loading || disabled || value === '') && 'cursor',
                    )}
                    loading={loading}
                >
                    <Typography
                        className="font-semibold text-lg"
                        color="text-[#292929]"
                    >
                        {t('common:button:apply')}
                    </Typography>
                </Button>
            </div>
        </div>
    );
};

export default FieldPoint;
